import {
  CloseButton,
  Combobox,
  type ComboboxOptionProps,
  ScrollArea,
  TextInput,
  type ComboboxStore,
  Loader,
  Flex,
  Group,
  Anchor,
  Center,
} from "@mantine/core";
import { type GetInputPropsReturnType } from "node_modules/@mantine/form/lib/types";
import { Highlight } from "@mantine/core";
import { isScrollThreasholdReached } from "./utils";
import { useEffect, useRef, useState } from "react";
import { IconEye } from "@tabler/icons-react";
import classes from "./Lookup.module.css";

type LookupProps = GetInputPropsReturnType & {
  combobox: ComboboxStore;
  required?: boolean;
  disabled?: boolean;
  searchTerm: string;
  setSearchTerm: (value: string) => void;
  lookupValue: string | null;
  setLookupValue: (value: string | null) => void;
  options: (JSX.Element | null)[] | undefined;
  header: JSX.Element;
  isFetching: boolean;
  currentFocus: number;
  entity: string;
  entityId: string | undefined;
};

export function Lookup({
  combobox,
  onChange,
  required = false,
  disabled = false,
  searchTerm,
  setSearchTerm,
  lookupValue,
  setLookupValue,
  options,
  header,
  isFetching,
  currentFocus,
  entity,
  entityId,
  ...props
}: LookupProps) {
  const viewportRef = useRef<HTMLDivElement>(null);
  const [currentId, setCurrentId] = useState<string | null>(entityId ?? null);
  useEffect(() => {
    viewportRef.current
      ?.querySelectorAll(".mantine-Combobox-option")
      ?.[currentFocus]?.scrollIntoView({ block: "nearest" });
  }, [currentFocus]);
  return (
    <Combobox
      store={combobox}
      withinPortal={true}
      onOptionSubmit={(val, optionProps: ComboboxOptionProps) => {
        combobox.closeDropdown();
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        onChange(val);
        setCurrentId(val);
        setLookupValue(optionProps.display as string);
        setSearchTerm(optionProps.display as string);
      }}
    >
      <Combobox.Target>
        <TextInput
          pointer
          required={required}
          disabled={disabled}
          contentEditable={true}
          {...props}
          value={
            lookupValue != null && lookupValue != "" ? lookupValue : searchTerm
          }
          onChange={(event) => {
            setLookupValue(event.currentTarget.value);
            setSearchTerm(event.currentTarget.value);
          }}
          leftSection={
            entity !== null &&
            currentId !== null && (
              <Anchor
                underline="always"
                href={`${document.location.origin}/app/${entity}/${currentId}?redirectTo=${document.location.pathname}`}
                className={classes.eyeAnchor}
              >
                <Center>
                  <IconEye />
                </Center>
              </Anchor>
            )
          }
          rightSection={
            currentId !== null ? (
              <CloseButton
                size="sm"
                onMouseDown={(event) => event.preventDefault()}
                onClick={() => {
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                  onChange(null);
                  setLookupValue(null);
                  setCurrentId(null);
                  setSearchTerm("");
                }}
                aria-label="Clear value"
              />
            ) : (
              <Combobox.Chevron />
            )
          }
          onClick={() => combobox.openDropdown()}
        ></TextInput>
      </Combobox.Target>
      <Combobox.Dropdown>
        <Combobox.Options>
          {options?.length !== 0 && (
            <Combobox.Header className={classes.lookupHeader}>
              <Group justify="space-between" gap="sm" grow>
                {header}
              </Group>
            </Combobox.Header>
          )}
          {isFetching ? (
            <Flex p={20} justify="center">
              <Loader></Loader>
            </Flex>
          ) : (
            <ScrollArea.Autosize
              mah={200}
              type="always"
              offsetScrollbars
              scrollbars="y"
              viewportRef={viewportRef}
            >
              {options?.length === 0 ? (
                <Combobox.Empty>{"Nothing Found..."}</Combobox.Empty>
              ) : (
                <>{options}</>
              )}
            </ScrollArea.Autosize>
          )}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
}

export const OptionGroupRender = (
  id: string,
  index: number,
  display: string,
  entityCount: number,
  infiniteScrollRef: React.RefObject<HTMLDivElement>,
  options: JSX.Element[],
) => {
  return (
    <Combobox.Option
      value={id}
      key={id}
      display={display}
      ref={
        isScrollThreasholdReached(index, entityCount)
          ? infiniteScrollRef
          : undefined
      }
    >
      <Group justify="space-between" gap="sm" grow>
        {options}
      </Group>
    </Combobox.Option>
  );
};

export const OptionRender = (
  value: string | null | undefined,
  searchTerm: string,
  id?: string,
) => {
  return (
    <Highlight highlight={searchTerm} size="sm" key={id}>
      {value ?? ""}
    </Highlight>
  );
};
