import { AppointmentCalendar } from "@/features/appointments/components/AppointmentCalendar";
import { useDateRange } from "@/features/appointments/hooks/useDateRange";
import { useEntityLazyUpdateMutation } from "@/features/entity/mutations";
import { type Schemas } from "@/types";
import { useCallback } from "react";
import { useQueryClient } from "react-query";

interface AppointmentsTabProps {
  leadId?: string;
  businessUnitId: string;
}

export function AppointmentsTab({
  leadId,
  businessUnitId,
}: AppointmentsTabProps) {
  const queryCache = useQueryClient();
  const {
    dateRange,
    setDateRange,
    setCustomDateRange,
    setCalendarView,
    calendarView,
  } = useDateRange("week");

  const { mutate: update } = useEntityLazyUpdateMutation<
    Schemas["Appointment"],
    Schemas["AppointmentCreateDto"]
  >({
    resourcePath: "/api/Appointments/{id}",
    queryKey: "appointment",
  });
  const refreshForm = async () => {
    await queryCache.invalidateQueries(
      `${dateRange.startDate}_${dateRange.endDate}_appointment_list`,
    );
  };
  const onDateChange = useCallback(
    (date: Date) => {
      setDateRange(date);
    },
    [setDateRange],
  );

  return (
    <AppointmentCalendar
      redirectTo={window.location.pathname}
      refreshForm={refreshForm}
      onDateChange={onDateChange}
      dateRange={dateRange}
      setDateRange={setCustomDateRange}
      calendarView={calendarView}
      setCalendarView={setCalendarView}
      onDrop={(range, resourceId) =>
        update({
          resourceId,
          params: range,
          queriesToInvalidate: [
            `${dateRange.startDate}_${dateRange.endDate}_appointment_list`,
          ],
        })
      }
      onResize={(range, resourceId) =>
        update({
          resourceId,
          params: range,
          queriesToInvalidate: [
            `${dateRange.startDate}_${dateRange.endDate}_appointment_list`,
          ],
        })
      }
      values={[businessUnitId]}
      leadId={leadId}
    />
  );
}
