import "react-big-calendar/lib/css/react-big-calendar.css";
import { Navigate, type View, type ToolbarProps } from "react-big-calendar";
import { Button, Flex, Group, useMantineTheme } from "@mantine/core";
import { DatePickerInput, type DatesRangeValue } from "@mantine/dates";
import { IconArrowLeft, IconArrowRight, IconFilter } from "@tabler/icons-react";
import { useState } from "react";
import { useMediaQuery } from "@mantine/hooks";
import "./index.css";

interface CustomToolbarProps extends ToolbarProps {
  openDrawer: () => void;
  closeDrawer: () => void;
  drawerIsOpen: boolean;
  dateRange: { startDate: string; endDate: string };
  setDateRange: (value: DatesRangeValue) => void;
  viewState: string;
  setViewState: (view: string) => void;
}

export default function CustomToolbar(props: CustomToolbarProps) {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);

  const [value, setValue] = useState<[Date | null, Date | null]>([
    new Date(props.dateRange?.startDate),
    new Date(props.dateRange?.endDate),
  ]);

  const viewButtons = [
    { label: "Month", view: "month" },
    { label: "Week", view: "week" },
    { label: "Day", view: "day" },
    { label: "Agenda", view: "agenda" },
  ];

  const handleViewChange = (view: View) => {
    props.onNavigate(Navigate.TODAY);
    props.onView(view);
    props.setViewState(view);
  };

  const handleCustomRangeChange = (range: DatesRangeValue) => {
    setValue(range);
    if (range[0] && range[1]) {
      props.setDateRange(range);
    }
  };

  const toggleFilters = () => {
    props.drawerIsOpen ? props.closeDrawer() : props.openDrawer();
  };

  return (
    <Flex justify="space-between" align="center" wrap="wrap" gap="xs" mb={5}>
      <Group gap="xs">
        <Group gap="xs" wrap="nowrap">
          <IconArrowLeft
            className="calendar-toolbar-icon"
            onClick={() => props.onNavigate(Navigate.PREVIOUS)}
            width={isMobile ? 16 : undefined}
            stroke={2}
            style={{ cursor: "pointer" }}
          />
          {isMobile ? null : (
            <DatePickerInput
              type="range"
              valueFormat="YYYY-MM-DD"
              allowSingleDateInRange
              numberOfColumns={isMobile ? 1 : 2}
              size="xs"
              disabled
              value={value}
              onChange={handleCustomRangeChange}
            />
          )}

          <IconArrowRight
            className="calendar-toolbar-icon"
            onClick={() => props.onNavigate(Navigate.NEXT)}
            width={isMobile ? 16 : undefined}
            stroke={2}
            style={{ cursor: "pointer" }}
          />
        </Group>
        {viewButtons.map((btn) => (
          <Button
            key={btn.view}
            variant={props.viewState === btn.view ? "outline" : "subtle"}
            onClick={() => handleViewChange(btn.view as View)}
            size={isMobile ? "xs" : "sm"}
          >
            {isMobile ? btn.label.charAt(0) : btn.label}
          </Button>
        ))}
        <Button
          variant={props.drawerIsOpen ? "filled" : "subtle"}
          onClick={toggleFilters}
          size={isMobile ? "xs" : "sm"}
        >
          {isMobile ? <IconFilter size={16} /> : "Filters"}
        </Button>
        <Button
          variant="subtle"
          onClick={() => props.onNavigate(Navigate.TODAY)}
        >
          {isMobile ? "T" : "Today"}
        </Button>
      </Group>
    </Flex>
  );
}
