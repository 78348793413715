import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { ErrorBoundary } from "react-error-boundary";
import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import { Suspense, type PropsWithChildren } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ErrorFallback } from "@/components/ErrorFallback";
import { queryClient } from "@/lib/query";
import { theme } from "@/lib/theme";
import { AuthProvider } from "./auth";
import { LayoutVisibilityProvider } from "@/components/Layout/Contexts/LayoutVisibility/LayoutVisibilityContext";
import "@/lib/i18n";
import { ModalsProvider } from "@mantine/modals";
import { storage } from "@/utils/storage";
import { useTranslation } from "react-i18next";
import { CustomDatesProvider } from "./date";
import { ErrorProvider } from "./error";
import { UserContextProvider } from "@/components/Layout/Contexts/User/UserContext";

export function AppProvider({ children }: PropsWithChildren) {
  const { i18n } = useTranslation();
  if (storage.getLanguage() !== i18n.language) {
    void i18n.changeLanguage(storage.getLanguage() as string);
  }

  return (
    <Suspense>
      <AuthProvider>
        <MantineProvider theme={theme}>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Notifications position="top-right" autoClose={1750} />
            <QueryClientProvider client={queryClient}>
              {import.meta.env.DEV ? <ReactQueryDevtools /> : null}
              <ErrorProvider>
                <CustomDatesProvider>
                  <ModalsProvider>
                    <Router>
                      <UserContextProvider>
                        <LayoutVisibilityProvider>
                          {children}
                        </LayoutVisibilityProvider>
                      </UserContextProvider>
                    </Router>
                  </ModalsProvider>
                </CustomDatesProvider>
              </ErrorProvider>
            </QueryClientProvider>
          </ErrorBoundary>
        </MantineProvider>
      </AuthProvider>
    </Suspense>
  );
}
