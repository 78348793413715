import { Box, Center, Chip, Group, Loader } from "@mantine/core";
import { useUserContext } from "../Contexts/User/useUserContext";
import { OCLeadTab } from "./OCLeadTab";
import { OmnichannelTimer } from "./OmnichannelTimer";
import { useEntityListQuery } from "@/features/entity/queries";
import { type Schemas } from "@/types";

export default function Omnichannel() {
  const { OCLeadValue, setOCLeadValue, finalUser } = useUserContext();
  const { data, isLoading, isRefetching } = useEntityListQuery<
    Schemas["LeadRetrieveDtoPagedList"]
  >({
    resourcePath: "/api/Leads",
    queryKey: "lead_omnichannel",
    params: {
      filter: `OwnerId == ${finalUser?.id}`,
    },
  });

  const leads = data?.data?.sort((a, b) => {
    if (a.id === OCLeadValue) return -1;
    if (b.id === OCLeadValue) return 1;
    return a.modifiedOn! < b.modifiedOn! ? -1 : 1;
  });

  if (isLoading || isRefetching) {
    return (
      <Center w="100%" h="100%">
        <Loader />
      </Center>
    );
  }

  const OCLeadsConverted = leads?.map((x) => {
    return {
      label: x.fullName ?? "Name Invalid",
      value: x.id ?? "Id Invalid",
    };
  });

  return (
    <Box w={"100%"}>
      <Chip.Group
        multiple={false}
        value={OCLeadValue}
        onChange={setOCLeadValue}
      >
        <Group
          justify="center"
          align="center"
          gap={"xl"}
          w={"100vw"}
          h={"5vh"}
          mt={".35vh"}
          ml={"1vw"}
        >
          {OCLeadsConverted?.map((OCLead) => {
            return (
              <OCLeadTab
                key={OCLead.value}
                label={OCLead.label}
                value={OCLead.value}
                currentValue={OCLeadValue}
                lead={leads?.find((x) => x.id === OCLead.value)}
              />
            );
          })}
        </Group>
      </Chip.Group>
      <OmnichannelTimer />
    </Box>
  );
}
