export const languageEnum = ["English", "Dutch"];
export const timeZoneEnum = [
  { value: "UTC", label: "UTC" },
  { value: "Europe_Amsterdam", label: "Europe/Amsterdam" },
  { value: "Europe_Andorra", label: "Europe/Andorra" },
  { value: "Europe_Astrakhan", label: "Europe/Astrakhan" },
  { value: "Europe_Athens", label: "Europe/Athens" },
  { value: "Europe_Belgrade", label: "Europe/Belgrade" },
  { value: "Europe_Berlin", label: "Europe/Berlin" },
  { value: "Europe_Brussels", label: "Europe/Brussels" },
  { value: "Europe_Bucharest", label: "Europe/Bucharest" },
  { value: "Europe_Budapest", label: "Europe/Budapest" },
  { value: "Europe_Chisinau", label: "Europe/Chisinau" },
  { value: "Europe_Copenhagen", label: "Europe/Copenhagen" },
  { value: "Europe_Dublin", label: "Europe/Dublin" },
  { value: "Europe_Gibraltar", label: "Europe/Gibraltar" },
  { value: "Europe_Guernsey", label: "Europe/Guernsey" },
  { value: "Europe_Helsinki", label: "Europe/Helsinki" },
  { value: "Europe_Isle_of_Man", label: "Europe/Isle_of_Man" },
  { value: "Europe_Istanbul", label: "Europe/Istanbul" },
  { value: "Europe_Jersey", label: "Europe/Jersey" },
  { value: "Europe_Lisbon", label: "Europe/Lisbon" },
  { value: "Europe_Ljubljana", label: "Europe/Ljubljana" },
  { value: "Europe_London", label: "Europe/London" },
  { value: "Europe_Luxembourg", label: "Europe/Luxembourg" },
  { value: "Europe_Madrid", label: "Europe/Madrid" },
  { value: "Europe_Malta", label: "Europe/Malta" },
  { value: "Europe_Mariehamn", label: "Europe/Mariehamn" },
  { value: "Europe_Minsk", label: "Europe/Minsk" },
  { value: "Europe_Monaco", label: "Europe/Monaco" },
  { value: "Europe_Moscow", label: "Europe/Moscow" },
  { value: "Europe_Oslo", label: "Europe/Oslo" },
  { value: "Europe_Paris", label: "Europe/Paris" },
  { value: "Europe_Podgorica", label: "Europe/Podgorica" },
  { value: "Europe_Prague", label: "Europe/Prague" },
  { value: "Europe_Riga", label: "Europe/Riga" },
  { value: "Europe_Rome", label: "Europe/Rome" },
  { value: "Europe_Samara", label: "Europe/Samara" },
  { value: "Europe_San_Marino", label: "Europe/San_Marino" },
  { value: "Europe_Sarajevo", label: "Europe/Sarajevo" },
  { value: "Europe_Skopje", label: "Europe/Skopje" },
  { value: "Europe_Sofia", label: "Europe/Sofia" },
  { value: "Europe_Stockholm", label: "Europe/Stockholm" },
  { value: "Europe_Tallinn", label: "Europe/Tallinn" },
  { value: "Europe_Tirane", label: "Europe/Tirane" },
  { value: "Europe_Uzhgorod", label: "Europe/Uzhgorod" },
  { value: "Europe_Vaduz", label: "Europe/Vaduz" },
  { value: "Europe_Vatican", label: "Europe/Vatican" },
  { value: "Europe_Vienna", label: "Europe/Vienna" },
  { value: "Europe_Vilnius", label: "Europe/Vilnius" },
  { value: "Europe_Warsaw", label: "Europe/Warsaw" },
  { value: "Europe_Zagreb", label: "Europe/Zagreb" },
  { value: "Europe_Zaporozhye", label: "Europe/Zaporozhye" },
  { value: "Europe_Zurich", label: "Europe/Zurich" },
  { value: "Etc_GMT_Minus_2", label: "Etc/GMT-2" },
  { value: "Etc_GMT_Minus_3", label: "Etc/GMT-3" },
  { value: "Etc_GMT_Minus_4", label: "Etc/GMT-4" },
  { value: "Etc_GMT_Minus_5", label: "Etc/GMT-5" },
  { value: "Etc_GMT_Minus_6", label: "Etc/GMT-6" },
  { value: "Etc_GMT_Minus_7", label: "Etc/GMT-7" },
  { value: "Etc_GMT_Minus_8", label: "Etc/GMT-8" },
];
export const timeZoneEnumStrings = timeZoneEnum.map(
  (timeZone) => timeZone.value,
);

export const getTimeZoneLabel = (value: string | undefined) => {
  const timezone = value
    ? timeZoneEnum.find((timezone) => timezone.value === value)
    : null;
  return timezone ? timezone.label : undefined;
};
