import {
  AppShell,
  Burger,
  Center,
  Flex,
  Group,
  Loader,
  Title,
} from "@mantine/core";
import { config } from "@/config";
import classes from "./Header.module.css";
import { UserMenu } from "../UserMenu/UserMenu";
import { Link } from "react-router-dom";
import { NotificationPopover } from "./NotificationPopover";
import { BusinessUnitSelect } from "@/components/Layout/Header/BusinessUnitSelect";
import Omnichannel from "../Omnichannel/Omnichannel";
import { useLayoutVisibility } from "../Contexts/LayoutVisibility/LayoutVisibilityContext";
import { useUserContext } from "../Contexts/User/useUserContext";

interface HeaderProps {
  mobileOpened: boolean;
  desktopOpened: boolean;
  onClickMobile: VoidFunction;
  onClickDesktop: VoidFunction;
  userBusinessUnitId: string | null;
  setUserBusinessUnitId: (
    businessUnitId: string | null,
    closeModal: boolean,
  ) => void;
}

export function Header({
  mobileOpened,
  desktopOpened,
  onClickMobile,
  onClickDesktop,
  userBusinessUnitId,
  setUserBusinessUnitId,
}: HeaderProps) {
  const { roles } = useUserContext();

  const { isOmnichannelVisible } = useLayoutVisibility();
  const { userUpdating } = useUserContext();

  if (userUpdating) {
    return (
      <AppShell.Header>
        <Center h={"100%"}>
          <Loader />
        </Center>
      </AppShell.Header>
    );
  }

  if (isOmnichannelVisible) {
    return (
      <AppShell.Header>
        <Omnichannel />
      </AppShell.Header>
    );
  }

  return (
    <AppShell.Header withBorder={true} className={classes.header}>
      <Group h="100%" px="md">
        <Burger
          opened={mobileOpened}
          onClick={onClickMobile}
          hiddenFrom="sm"
          size="sm"
          color="white"
        />
        <Burger
          opened={desktopOpened}
          onClick={onClickDesktop}
          visibleFrom="sm"
          size="sm"
          color="white"
        />
        <Link to="/app" style={{ textDecoration: "none" }}>
          <Title size={20} order={2} style={{ color: "#fff" }}>
            {config.PRODUCT_TITLE}
          </Title>
        </Link>
      </Group>
      <Flex
        gap="md"
        justify="flex-end"
        align="center"
        direction="row"
        wrap="nowrap"
        mr={10}
      >
        {roles.includes("Store Manager") && (
          <BusinessUnitSelect
            businessUnitId={userBusinessUnitId}
            setBusinessUnitId={setUserBusinessUnitId}
            closeModal={false}
          />
        )}
        <NotificationPopover />
        <UserMenu />
      </Flex>
    </AppShell.Header>
  );
}
