import { Select } from "@mantine/core";
import useLanguage from "@/hooks/useLanguage";
import { type Locale } from "@/config/locale";

const languageOptions = [
  { label: "English", value: "en" },
  { label: "Dutch", value: "nl" },
];

export function UserLanguage() {
  const { language, changeLanguage } = useLanguage();

  const handleSelectChange = (value: string | null) => {
    if (!value) return;
    changeLanguage(value as Locale);
  };

  return (
    <Select
      type="text"
      onChange={handleSelectChange}
      value={language}
      data={languageOptions}
    />
  );
}
