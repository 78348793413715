import { type Schemas } from "@/types";
import { AppointmentIcon, TrailerIcon, MovingVanIcon } from "@/assets/icons";
import {
  Box,
  Image,
  Tooltip,
  Text,
  Group,
  Stack,
  useMantineTheme,
  Flex,
  rgba,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { DateTime } from "luxon";

interface EventProps {
  event: Schemas["Appointment"];
  calendarView: string;
}

const MonthView = ({ event }: { event: Schemas["Appointment"] }) => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
  const isTablet = useMediaQuery(`(max-width: ${theme.breakpoints.lg})`);

  return (
    <Group wrap="nowrap" p={isMobile ? 1 : 2}>
      {!isMobile && !isTablet && <Box>{renderIcon(event)}</Box>}
      <Box p={isMobile ? 1 : 2}>
        <Text size={isMobile ? "xs" : "sm"} fw={700} lineClamp={1}>
          {event.appointmentType}
        </Text>
        <Text size="xs">
          {`${DateTime.fromJSDate(new Date(event.startDate ?? "")).toFormat("HH:mm")} - ${DateTime.fromJSDate(new Date(event.endDate ?? "")).toFormat("HH:mm")}`}
        </Text>
      </Box>
      <Flex direction="column" justify="center">
        <Text ta={"left"} size={"xs"} fw={400}>
          {event.contact?.firstName}
        </Text>
        <Text ta={"left"} size={"xs"} fw={400}>
          {event.contact?.lastName}
        </Text>
      </Flex>
    </Group>
  );
};

const WeekView = ({ event }: { event: Schemas["Appointment"] }) => {
  return (
    <Flex ml={4} direction="column">
      <Box style={{ fontSize: 12 }} mb={6}>
        {`${DateTime.fromJSDate(new Date(event.startDate ?? "")).toFormat("HH:mm")} - ${DateTime.fromJSDate(new Date(event.endDate ?? "")).toFormat("HH:mm")}`}
      </Box>
      <Text ta={"left"} size={"xs"} fw={400}>
        {event.contact?.fullName}
      </Text>
      <Text size={"xs"} fw={700} lineClamp={1}>
        {event.appointmentType}
      </Text>
      <Text size={"xs"} fw={700}>
        {event.businessUnit?.code}
      </Text>
    </Flex>
  );
};

const DayView = ({ event }: { event: Schemas["Appointment"] }) => {
  return (
    <Flex ml={4} direction="column">
      <Flex direction="row">
        <Box style={{ fontSize: 14 }} mb={6}>
          {`${DateTime.fromJSDate(new Date(event.startDate ?? "")).toFormat("HH:mm")} - ${DateTime.fromJSDate(new Date(event.endDate ?? "")).toFormat("HH:mm")}`}
        </Box>
        <Text ml={10} fw={700} ta={"left"} size={"xs"}>
          {event.contact?.fullName}
        </Text>
      </Flex>
      <Text size={"xs"} fw={700} lineClamp={1}>
        {event.appointmentType}
      </Text>
      <Text size={"xs"} fw={700}>
        {event.businessUnit?.code}
      </Text>
    </Flex>
  );
};

const AgendaView = ({ event }: { event: Schemas["Appointment"] }) => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  return (
    <Group wrap="nowrap" p={isMobile ? 1 : 2}>
      <Box>{renderIcon(event)}</Box>
      <Stack>
        <Flex direction="row">
          <Text size={isMobile ? "xs" : "sm"} fw={700} mr={10} lineClamp={1}>
            {event.appointmentType}
          </Text>
          <Text size={isMobile ? "xs" : "sm"} fw={700} lineClamp={1}>
            {event.contact?.fullName}
          </Text>
        </Flex>
        <Text size="xs">{`${DateTime.fromJSDate(new Date(event.startDate ?? "")).toFormat("yyyy-MM-dd HH:mm")} - ${DateTime.fromJSDate(new Date(event.endDate ?? "")).toFormat("HH:mm")}`}</Text>
        <Text size="xs">{event.businessUnit?.code}</Text>
      </Stack>
    </Group>
  );
};

const renderIcon = (event: Schemas["Appointment"]) => {
  switch (event.appointmentType) {
    case "BlockCalendar":
    case "Desk":
    case "SpaceTour":
      return <Image src={AppointmentIcon} width={12} height={22} />;
    case "MovingVan":
      return <Image src={MovingVanIcon} width={12} height={22} />;
    case "Trailer":
      return <Image src={TrailerIcon} width={12} height={22} />;
    default:
      return <Image src={AppointmentIcon} width={12} height={22} />;
  }
};

export function AppointmentEvent({ event, calendarView }: EventProps) {
  const duration =
    new Date(event.endDate ?? "").getTime() -
    new Date(event.startDate ?? "").getTime();
  const hours = Math.floor(duration / (1000 * 60 * 60));
  const minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));
  const durationString = `${hours}H:${minutes}M`;

  const renderContent = () => {
    switch (calendarView) {
      case "month":
        return <MonthView event={event} />;
      case "week":
        return <WeekView event={event} />;
      case "day":
        return <DayView event={event} />;
      case "agenda":
        return <AgendaView event={event} />;
      default:
        return null;
    }
  };

  let backgroundColorToUse = "#6082B6"; // Fallback background (blue) color
  let textColorToUse = "#000000"; // Fallback text color

  // Check for appointmentStatus and set color accordingly
  switch (event.appointmentStatus) {
    case "NoShow":
      backgroundColorToUse = "#F5F4F2"; // Color for 'NoShow'
      textColorToUse = "#A9A9A9"; // Text color for 'NoShow'
      break;
    case "Completed":
      backgroundColorToUse = "#C1E1C1"; // Color for 'Completed'
      textColorToUse = "#40826D"; // Text color for 'Completed'
      break;
    default:
      backgroundColorToUse =
        event.businessUnit?.color == "" ||
        event.businessUnit?.color == null ||
        event.businessUnit?.color == undefined
          ? backgroundColorToUse
          : event.businessUnit?.color; // Color for 'default'
      break;
  }

  switch (event.appointmentType) {
    case "BlockCalendar":
      backgroundColorToUse = "#e95a4a"; // Color for 'Completed'
      textColorToUse = "#A42A04"; // Text color for 'Completed'
      break;
  }

  return (
    <Tooltip.Floating
      multiline
      position="top-start"
      w={"fit-content"}
      withinPortal
      label={
        <Stack gap={0}>
          <Text fw={700} c={textColorToUse}>
            {event.appointmentType}
          </Text>
          <Text fw={700} c={textColorToUse}>
            {event.contact?.fullName}
          </Text>
          <Text fw={700} c={textColorToUse}>
            {event.businessUnit?.code}
          </Text>
          <Text
            size="xs"
            c={textColorToUse}
          >{`Start: ${DateTime.fromJSDate(new Date(event.startDate ?? "")).toFormat("yyyy-MM-dd HH:mm")}`}</Text>
          <Text
            size="xs"
            c={textColorToUse}
          >{`End: ${DateTime.fromJSDate(new Date(event.endDate ?? "")).toFormat("yyyy-MM-dd HH:mm")}`}</Text>
          <Text
            size="xs"
            c={textColorToUse}
          >{`Duration: ${durationString}`}</Text>
        </Stack>
      }
      styles={{
        tooltip: {
          backgroundColor: rgba(backgroundColorToUse, 0.85),
          border: `1px solid ${textColorToUse}`,
        },
      }}
    >
      <Box w={"100%"} h={"100%"} pt={5}>
        {renderContent()}
      </Box>
    </Tooltip.Floating>
  );
}
