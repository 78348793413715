import { type Dispatch, type SetStateAction, useRef, useState } from "react";
import { z } from "zod";
import { useTranslation } from "react-i18next";
import { useForm } from "@mantine/form";
import { zodResolver } from "mantine-form-zod-resolver";
import {
  ActionIcon,
  Box,
  Button,
  Fieldset,
  Flex,
  Select,
  Textarea,
  Title,
  rem,
} from "@mantine/core";
import { IconClock, IconSquareX } from "@tabler/icons-react";
import { AppointmentType, AppointmentStatus } from "@/types/enums";
import { DateInput, TimeInput } from "@mantine/dates";
import { LeadLookupField } from "@/features/leads";
import { ContactLookupField } from "@/features/contacts";
import { BusinessUnitLookupField } from "@/features/businessUnits";
import { type ReactNode } from "react";
import { useDebounceCallback } from "usehooks-ts";
import { config } from "@/config";
import { getEnumTransKey } from "@/utils/trans";
import { getDirtyFormFields } from "@/features/entity/utils";
import { DurationCombobox, GetDurationString } from "./DurationCombobox";
import { type Schemas } from "@/types";
import { useNavigate } from "react-router-dom";
import { CloseAppointmentButtons } from "./CloseAppointmentButtons";

const appointmentTypeEnum = z.enum(AppointmentType as [string]);
const appointmentStatusEnum = z.enum(AppointmentStatus as [string]);

const formSchema = z.object({
  id: z.string(),
  description: z.string(),
  startDate: z.date().nullable(),
  endDate: z.date().nullable(),
  businessUnitId: z.string().nullable(),
  businessUnit: z.object({}).nullable(),
  leadId: z.string().nullable(),
  lead: z.object({}).nullable(),
  contactId: z.string().nullable(),
  contact: z.object({}).nullable(),
  appointmentType: appointmentTypeEnum.refine((value) => !!value).nullable(),
  appointmentStatus: appointmentStatusEnum.refine((value) => !!value),
});

export type FormSchema = z.infer<typeof formSchema>;

interface AppointmentFormProps {
  onSubmit: (values: Partial<FormSchema>) => void;
  onDelete?: VoidFunction;
  actionSection?: ReactNode;
  initialValues?: FormSchema;
  isCreate: boolean;
  fromCalendar?: boolean;
  showBackButton?: boolean;
  closeModal?: () => void;
  isModal?: boolean;
  setPhantomEvent?: Dispatch<SetStateAction<Schemas["Appointment"] | null>>;
}

export function AppointmentForm({
  onSubmit,
  initialValues,
  isCreate,
  fromCalendar,
  closeModal,
  isModal,
  setPhantomEvent,
}: AppointmentFormProps) {
  const { t } = useTranslation("features");
  const debouncedOnSubmit = useDebounceCallback(onSubmit, 500);
  const [editableForm, setEditableForm] = useState(isCreate ? true : false);
  const refStart = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const refEnd = useRef<HTMLInputElement>(null);
  const [formKey, setFormKey] = useState(0);
  const resetForm = () => {
    setFormKey((prev) => prev + 1);
  };

  const form = useForm<FormSchema>({
    initialValues: {
      id: initialValues?.id ?? "",
      description: initialValues?.description ?? "",
      startDate: initialValues?.startDate ?? new Date(),
      endDate: initialValues?.endDate ?? new Date(),
      businessUnitId: initialValues?.businessUnitId ?? "",
      businessUnit: initialValues?.businessUnit ?? null,
      leadId: initialValues?.leadId ?? "",
      lead: initialValues?.lead ?? null,
      contactId: initialValues?.contactId ?? "",
      contact: initialValues?.contact ?? null,
      appointmentType: initialValues?.appointmentType ?? null,
      appointmentStatus: initialValues?.appointmentStatus ?? "",
    },
    initialDirty: { startDate: true, endDate: true },
    validate: zodResolver(formSchema),
  });

  const startTimeControl = (
    <ActionIcon
      variant="subtle"
      color="gray"
      onClick={() => refStart.current?.showPicker()}
    >
      <IconClock style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
    </ActionIcon>
  );

  const endTimeControl = (
    <ActionIcon
      variant="subtle"
      color="gray"
      onClick={() => refEnd.current?.showPicker()}
    >
      <IconClock style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
    </ActionIcon>
  );

  const isBlocking = form.getValues().appointmentType === "BlockCalendar";

  if (
    form.values.appointmentType == "BlockCalendar" &&
    form.getValues().leadId != ""
  ) {
    form.setFieldValue("leadId", "");
    form.setFieldValue("lead", null);
  }

  return (
    <>
      <form
        key={formKey}
        onSubmit={form.onSubmit((fields) => {
          const filteredFields = getDirtyFormFields(
            fields,
            isCreate,
            form.isDirty,
          );
          debouncedOnSubmit(filteredFields);
        })}
        onChange={() => {
          if (setPhantomEvent) {
            setPhantomEvent({
              startDate: form.values.startDate?.toString(),
              endDate: form.values.endDate?.toString(),
            });
          }
        }}
      >
        <Flex justify="space-between" align="center" direction="row">
          <Title order={3}>
            {isCreate
              ? t("appointments.createAppointment")
              : t("appointments.editAppointment")}
          </Title>
          {fromCalendar ? (
            <IconSquareX
              width={44}
              className="calendar-toolbar-icon"
              onClick={() => {
                if (closeModal) {
                  closeModal();
                } else {
                  navigate(-1);
                }
              }}
            />
          ) : null}
        </Flex>

        <Box
          mr={10}
          style={!editableForm ? { pointerEvents: "none", opacity: "0.6" } : {}}
        >
          <Select
            mt={1}
            required
            clearable
            searchable
            label={t("appointments.appointmentType")}
            data={AppointmentType.map((value) => ({
              value,
              label: t(getEnumTransKey("appointments", value)),
            }))}
            value={form.values.appointmentType ?? null}
            {...form.getInputProps("appointmentType")}
            {...{
              labelProps: {
                style: {
                  flex: isModal ? "0.75" : "1.225",
                  fontSize: "0.7rem",
                  fontWeight: "bold",
                },
              },
            }}
          />

          <BusinessUnitLookupField
            flex={1}
            required
            mt={0}
            label={t("appointments.businessUnit")}
            initial={form.getValues().businessUnit}
            {...form.getInputProps("businessUnitId")}
            {...{
              labelProps: {
                style: {
                  flex: isModal ? "0.75" : "1.225",
                  fontSize: "0.7rem",
                  fontWeight: "bold",
                },
              },
            }}
          />

          <Fieldset disabled={isBlocking}>
            {!isBlocking && (
              <>
                <LeadLookupField
                  flex={1}
                  mt={0}
                  label={t("appointments.lead")}
                  initial={form.getValues().lead}
                  {...form.getInputProps("leadId")}
                  {...{
                    labelProps: {
                      style: {
                        flex: isModal ? "0.75" : "1.225",
                        fontSize: "0.7rem",
                        fontWeight: "bold",
                      },
                    },
                  }}
                />
                <ContactLookupField
                  flex={1}
                  mt={0}
                  disabled={form.getValues().leadId ? true : false}
                  label={t("appointments.contact")}
                  initial={form.getValues().contact}
                  {...form.getInputProps("contactId")}
                  {...{
                    labelProps: {
                      style: {
                        flex: isModal ? "0.75" : "1.225",
                        fontSize: "0.7rem",
                        fontWeight: "bold",
                      },
                    },
                  }}
                />
              </>
            )}
            {isBlocking && <Flex m={42} />}
          </Fieldset>
          <Flex
            gap={"sm"}
            direction={{ base: "column", md: "column", lg: "row" }}
          >
            <DateInput
              flex={2}
              valueFormat={config.DATES.dateFormat}
              size="xs"
              clearable
              label={t("appointments.startDate")}
              {...form.getInputProps("startDate")}
              {...{
                labelProps: {
                  style: {
                    flex: isModal ? "1.75" : "3.2",
                    fontSize: "0.7rem",
                    fontWeight: "bold",
                  },
                },
              }}
            />
            <TimeInput
              size="xs"
              flex={2}
              defaultValue={form.values.startDate?.toLocaleTimeString("en-US", {
                hour: "2-digit",
                minute: "2-digit",
                hour12: false,
              })}
              onChange={(event) => {
                if (event.target.value !== undefined) {
                  const value = event.target.value.split(":");
                  if (value.length == 2) {
                    const hours = parseInt(value[0] ?? "");
                    const minutes = parseInt(value[1] ?? "");
                    form.values.startDate?.setHours(hours, minutes);
                    resetForm();
                  }
                }
              }}
              label={"Start Time"}
              ref={refStart}
              rightSection={startTimeControl}
              {...{
                labelProps: {
                  style: {
                    flex: isModal ? "1" : undefined,
                    fontSize: "0.7rem",
                    fontWeight: "bold",
                  },
                },
              }}
            />
          </Flex>
          <Flex
            gap={"sm"}
            direction={{ base: "column", md: "column", lg: "row" }}
          >
            <DurationCombobox
              startDate={form.values.startDate}
              endDate={form.values.endDate}
              setEndDate={(val: string) => {
                const value = val.split(":");
                if (value.length == 2) {
                  const hours = parseInt(value[0] ?? "");
                  const minutes = parseInt(value[1] ?? "");
                  form.values.endDate?.setHours(
                    (form.values.startDate?.getHours() ?? 0) + hours,
                    (form.values.startDate?.getMinutes() ?? 0) + minutes,
                  );
                  if (setPhantomEvent) {
                    setPhantomEvent({
                      startDate: form.values.startDate?.toString(),
                      endDate: form.values.endDate?.toString(),
                    });
                  }
                  resetForm();
                }
              }}
              isModal={isModal}
            />
            <Box flex={1} mt="sm">
              {`${GetDurationString(form.values.startDate!, form.values.endDate!)}`}
            </Box>
          </Flex>
          <Flex
            gap={"sm"}
            direction={{ base: "column", md: "column", lg: "row" }}
          >
            <DateInput
              flex={2}
              size="xs"
              valueFormat={config.DATES.dateFormat}
              clearable
              label={t("appointments.endDate")}
              {...form.getInputProps("endDate")}
              {...{
                labelProps: {
                  style: {
                    flex: isModal ? "1.75" : "3.2",
                    fontSize: "0.7rem",
                    fontWeight: "bold",
                  },
                },
              }}
            />
            <TimeInput
              flex={2}
              size="xs"
              defaultValue={form.values.endDate?.toLocaleTimeString("en-US", {
                hour: "2-digit",
                minute: "2-digit",
                hour12: false,
              })}
              onChange={(event) => {
                if (event.target.value !== undefined) {
                  const value = event.target.value.split(":");
                  if (value.length == 2) {
                    const hours = parseInt(value[0] ?? "");
                    const minutes = parseInt(value[1] ?? "");
                    form.values.endDate?.setHours(hours, minutes);
                    resetForm();
                  }
                }
              }}
              label={"End Time"}
              ref={refEnd}
              rightSection={endTimeControl}
              {...{
                labelProps: {
                  style: {
                    flex: isModal ? "1" : undefined,
                    fontSize: "0.7rem",
                    fontWeight: "bold",
                  },
                },
              }}
            />
          </Flex>
          <Textarea
            mt={0}
            label={t("appointments.description")}
            {...form.getInputProps("description")}
            minRows={4}
            autosize
            {...{
              labelProps: {
                style: {
                  flex: isModal ? "0.75" : "1.25",
                  fontSize: "0.7rem",
                  fontWeight: "bold",
                },
              },
            }}
          />
        </Box>

        <Flex
          mt={10}
          mr={10}
          gap="xs"
          justify="center"
          align="center"
          direction="row"
          wrap="nowrap"
        >
          {!editableForm && (
            <>
              <Button
                disabled={initialValues?.appointmentStatus != "Open"}
                display={isCreate ? "none" : undefined}
                variant="outline"
                w={"100%"}
                onClick={() => {
                  setEditableForm(true);
                }}
              >
                Edit
              </Button>
              <CloseAppointmentButtons
                currentState={initialValues?.appointmentStatus}
                appointmentId={initialValues?.id ?? ""}
              ></CloseAppointmentButtons>
            </>
          )}
          {editableForm && (
            <Button
              variant="outline"
              w={"100%"}
              style={
                !editableForm ? { pointerEvents: "none", opacity: "0.6" } : {}
              }
              type="submit"
            >
              Save & Send
            </Button>
          )}
        </Flex>
      </form>
    </>
  );
}
