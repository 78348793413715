import {
  useEntityDeleteMutation,
  useEntityUpdateMutation,
} from "@/features/entity/mutations";
import { useEntityQuery } from "@/features/entity/queries";
import { type Schemas } from "@/types";
import { useTranslation } from "react-i18next";
import {
  AppointmentForm,
  type FormSchema,
} from "../components/AppointmentForm";
import { filterFalsyValues } from "@/utils/filters";
import { notifications } from "@mantine/notifications";
import { Loader } from "@mantine/core";
import { useParams } from "react-router-dom";

interface AppointmentShowProps {
  refreshForm?: () => void;
  closeModal?: () => void;
  isModal?: boolean;
  fromCalendar?: boolean;
  appointmentId?: string | null;
}

export function AppointmentShow({
  refreshForm,
  closeModal,
  isModal,
  fromCalendar,
  appointmentId,
}: AppointmentShowProps) {
  const { t } = useTranslation("features");
  const { id } = useParams<{ id: string }>();
  const idToUse = appointmentId ?? id;
  const {
    data = {},
    isLoading,
    isFetching,
  } = useEntityQuery<Schemas["Appointment"]>({
    resourcePath: "/api/Appointments/{id}",
    resourceId: idToUse!,
    queryKey: "appointment",
  });

  const { mutate: update } = useEntityUpdateMutation<
    Schemas["Appointment"],
    Schemas["AppointmentCreateDto"]
  >({
    resourcePath: "/api/Appointments/{id}",
    resourceId: idToUse!,
    queryKey: "appointment",
  });

  const { mutateAsync, isError: isDeleteError } = useEntityDeleteMutation({
    resourcePath: "/api/Appointments/{id}",
    resourceId: idToUse!,
    queryKey: "appointment",
  });

  if (isLoading || isFetching) {
    return <Loader />;
  }

  return (
    <AppointmentForm
      isCreate={false}
      isModal={isModal}
      fromCalendar={fromCalendar}
      closeModal={closeModal}
      initialValues={
        filterFalsyValues({
          ...data,
          startDate: data.startDate ? new Date(data.startDate) : null,
          endDate: data.endDate ? new Date(data.endDate) : null,
        }) as FormSchema
      }
      onSubmit={(values) => {
        update(values as Schemas["AppointmentCreateDto"], {
          onSuccess: () => {
            if (refreshForm) {
              refreshForm();
            }
            notifications.show({
              color: "green",
              title: t("appointments.updateSuccessTitle"),
              message: t("appointments.updateSuccessMessage"),
            });
          },
        });
      }}
      onDelete={async () => {
        await mutateAsync();
        if (!isDeleteError) {
          if (refreshForm) {
            refreshForm();
          }
        }
      }}
    />
  );
}
