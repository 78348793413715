import { DateTime } from "luxon";

export function formatDateTime(date: string) {
  const dateTime = DateTime.fromISO(date);
  return dateTime.toFormat("yyyy-MM-dd HH:mm:ss");
}

export function formatDateTimeForBackend(date: Date) {
  return formatDateTime(date.toISOString());
}

export function getStartOfTheMonth(date: Date) {
  return DateTime.fromJSDate(date)
    .startOf("month")
    .toFormat("yyyy-MM-dd'T'HH:mm:ss");
}

export function getEndOfTheMonth(date: Date) {
  return DateTime.fromJSDate(date)
    .endOf("month")
    .plus({ seconds: 1 })
    .toFormat("yyyy-MM-dd'T'HH:mm:ss");
}
export function getStartOfTheWeek(date: Date) {
  return DateTime.fromJSDate(date)
    .startOf("week")
    .toFormat("yyyy-MM-dd'T'HH:mm:ss");
}

export function getEndOfTheWeek(date: Date) {
  return DateTime.fromJSDate(date)
    .endOf("week")
    .plus({ seconds: 1 })
    .toFormat("yyyy-MM-dd'T'HH:mm:ss");
}

export function getStartOfTheDay(date: Date) {
  return DateTime.fromJSDate(date)
    .startOf("day")
    .toFormat("yyyy-MM-dd'T'HH:mm:ss");
}

export function getEndOfTheDay(date: Date) {
  return DateTime.fromJSDate(date)
    .endOf("day")
    .plus({ seconds: 1 })
    .toFormat("yyyy-MM-dd'T'HH:mm:ss");
}
