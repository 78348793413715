import { useTranslation } from "react-i18next";
import { useEntityCreateMutation } from "@/features/entity/mutations";
import { useSearchParams } from "react-router-dom";
import { filterFalsyValues } from "@/utils/filters";
import { type Schemas } from "@/types";
import { AppointmentForm } from "../components/AppointmentForm";
import { notifications } from "@mantine/notifications";
import { useEntityQuery } from "@/features/entity/queries";
import { type Dispatch, type SetStateAction } from "react";

interface AppointmentCreateProps {
  refreshForm?: () => void;
  closeModal?: () => void;
  leadId?: string;
  businessUnitId?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  isModal?: boolean;
  setPhantomEvent?: Dispatch<SetStateAction<Schemas["Appointment"] | null>>;
}

export function AppointmentCreate({
  refreshForm: refreshAllAppointments,
  closeModal,
  leadId: leadIdProp,
  businessUnitId,
  startDate,
  endDate,
  isModal,
  setPhantomEvent,
}: AppointmentCreateProps) {
  const [searchParams] = useSearchParams();
  const { mutate } = useEntityCreateMutation<
    Schemas["Appointment"],
    Schemas["AppointmentCreateDto"]
  >({ resourcePath: "/api/Appointments", queryKey: "appointment" });
  const { t } = useTranslation("features");
  const leadId = searchParams.get("leadId");
  const leadIdToUse = leadId ?? leadIdProp;
  const {
    data: lead = {},
    isLoading,
    isFetching,
  } = useEntityQuery<Schemas["Lead"]>({
    resourcePath: "/api/Leads/{id}",
    resourceId: leadIdToUse!,
    queryKey: "leadAppointment",
  });
  const {
    data: businessUnit = {},
    isLoading: isLoadingBu,
    isFetching: isFetchingBU,
  } = useEntityQuery<Schemas["Lead"]>({
    resourcePath: "/api/BusinessUnits/{id}",
    resourceId: businessUnitId ?? "",
    queryKey: "businessUnitAppointment",
  });
  if (isLoading || isFetching || isLoadingBu || isFetchingBU) {
    return <></>;
  }
  const initialValues = {
    id: "",
    description: "",
    subject: "",
    startDate: startDate ? new Date(startDate) : null,
    endDate: endDate ? new Date(endDate) : null,
    businessUnitId: businessUnitId ?? "",
    businessUnit: businessUnit,
    contactId: lead?.existingContactId ?? "",
    contact: lead?.existingContact ?? null,
    leadId: leadIdToUse ?? "",
    lead: lead,
    appointmentType: null,
    appointmentStatus: "Open",
  };
  return (
    <AppointmentForm
      isCreate={true}
      isModal={isModal}
      showBackButton={false}
      initialValues={initialValues}
      closeModal={closeModal}
      setPhantomEvent={setPhantomEvent}
      onSubmit={(values) => {
        const filteredValues = filterFalsyValues(values);

        mutate(filteredValues, {
          onSuccess: () => {
            notifications.show({
              color: "green",
              title: t("appointments.createSuccessTitle"),
              message: t("appointments.createSuccessMessage"),
            });
            if (refreshAllAppointments) {
              refreshAllAppointments();
            }
            if (closeModal) {
              closeModal();
            }
            if (setPhantomEvent) {
              setPhantomEvent(null);
            }
          },
        });
      }}
    />
  );
}
