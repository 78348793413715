import { type QueryParams } from "@/features/entity/api";
import { useEntityListQuery } from "@/features/entity/queries";
import { type Schemas } from "@/types";
import {
  Center,
  Flex,
  Loader,
  Pagination,
  ScrollArea,
  TextInput,
  MultiSelect,
  Stack,
  Checkbox,
  Radio,
} from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { type Dispatch, type SetStateAction, useState } from "react";
import { AppointmentType } from "@/types/enums";

import classes from "./appointmentBuDrawer.module.css";
import { useUserContext } from "@/components/Layout/Contexts/User/useUserContext";
interface AppointmentBuDrawerProps {
  values: string[];
  setValues: Dispatch<SetStateAction<string[]>>;
  appointmentTypes: string[];
  setAppointmentTypes: Dispatch<SetStateAction<string[]>>;
  appointmentSearch: string;
  setAppointmentSearch: Dispatch<SetStateAction<string>>;
}

export function AppointmentBuDrawer({
  values,
  setValues,
  appointmentTypes,
  setAppointmentTypes,
  appointmentSearch,
  setAppointmentSearch,
}: AppointmentBuDrawerProps) {
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 300);
  const [activePage, setPage] = useState(0);
  const { roles } = useUserContext();
  const isSalesAndServiceUser = roles.includes("Sales");

  const [activeBU, setActiveBU] = useState<string[]>(values);

  let queryParams: QueryParams = {
    pageSize: 50,
    pageNumber: activePage,
  };

  if (debouncedSearchTerm) {
    queryParams = {
      pageSize: undefined,
      searchBy: debouncedSearchTerm,
    };
  }

  const handleAppointmentSearch = (val: string) => {
    setAppointmentSearch(val);
  };

  const handleTypeSelect = (val: string[]) => {
    setAppointmentTypes(val);
  };

  const { data, isFetching, isLoading } = useEntityListQuery<
    Schemas["BusinessUnitRetrieveDtoPagedList"]
  >({
    resourcePath: "/api/BusinessUnits",
    queryKey: "businessUnit",
    params: queryParams,
  });

  if (isFetching || isLoading) {
    return <Loader />;
  }

  const allBusinessUnits = data?.data?.flatMap((page) => page ?? []) ?? [];
  // Conditional rendering based on isSalesAndServiceUser
  const BUList = () => {
    if (isSalesAndServiceUser) {
      // Single selection using Radio.Group
      return (
        <Radio.Group
          value={activeBU[0] || ""}
          onChange={(value) => {
            const selectedValue = value ? [value] : [];
            setActiveBU(selectedValue);
            setValues(selectedValue);
          }}
        >
          <Stack>
            {allBusinessUnits.map((x) => (
              <label key={x.code} style={{ width: "100%" }}>
                <Radio
                  w={"100%"}
                  classNames={classes}
                  value={x.id}
                  label={x.code}
                  radius="xl"
                  size="md"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (activeBU[0] === x.id) {
                      // Deselect the radio button
                      setActiveBU([]);
                      setValues([]);
                    } else {
                      // Select the radio button
                      setActiveBU([x.id!]);
                      setValues([x.id!]);
                    }
                  }}
                />
              </label>
            ))}
          </Stack>
        </Radio.Group>
      );
    } else {
      return (
        <Checkbox.Group
          value={activeBU}
          onChange={(value) => {
            setActiveBU(value);
            setValues(value);
          }}
        >
          <Stack>
            {allBusinessUnits.map((x) => (
              <label key={x.code} style={{ width: "100%" }}>
                <Checkbox
                  w={"100%"}
                  classNames={classes}
                  value={x.id}
                  label={x.code}
                  radius="xl"
                  size="md"
                />
              </label>
            ))}
          </Stack>
        </Checkbox.Group>
      );
    }
  };
  return (
    <>
      <Center>{"Appointment Type Filter"}</Center>
      <MultiSelect
        data={AppointmentType}
        value={appointmentTypes}
        onChange={handleTypeSelect}
        clearable
        hidePickedOptions
        mb={10}
      />
      <TextInput
        value={appointmentSearch}
        onChange={(event) => handleAppointmentSearch(event.currentTarget.value)}
        placeholder="Search appointments..."
        autoFocus
        mb={10}
      />

      <TextInput
        value={searchTerm}
        onChange={(event) => setSearchTerm(event.currentTarget.value)}
        placeholder="Search business unit..."
        autoFocus
        mb={10}
      />
      <ScrollArea.Autosize
        mah={"70vh"}
        mih={"70vh"}
        type="hover"
        w={"100%"}
        maw={"16vw"}
        miw={"16vw"}
        scrollbars="y"
        scrollbarSize={0}
        style={{ overflow: "hidden" }}
      >
        {BUList()}
      </ScrollArea.Autosize>
      <Flex
        pt={10}
        justify="center"
        align="center"
        direction="row"
        wrap="wrap"
        w={"100%"}
      >
        <Pagination
          value={activePage + 1}
          size="xs"
          boundaries={1}
          siblings={1}
          total={data?.totalPages ?? 1}
          onChange={(value) => {
            setPage(value - 1);
          }}
        />
      </Flex>
    </>
  );
}
