import {
  Fieldset,
  TextInput,
  Input,
  createTheme,
  Select,
  type MantineColorsTuple,
  Textarea,
  NumberInput,
  ColorInput,
} from "@mantine/core";
import classes from "./theme.module.css";
import { TimeInput, DateInput, DatePickerInput } from "@mantine/dates";

const primary: MantineColorsTuple = [
  "#00223a",
  "#002844",
  "#002e4e",
  "#003357",
  "#228be6",
  "#228be6",
  "#1a4d71",
  "#336181",
  "#4d7490",
  "#6688a0",
];

const secondary: MantineColorsTuple = [
  "#fffae1",
  "#fff4cc",
  "#ffe99b",
  "#ffdc64",
  "#ffd238",
  "#ffcb1c",
  "#ffc809",
  "#e3b000",
  "#ca9c00",
  "#ae8600",
];

export const theme = createTheme({
  colors: {
    primary,
    secondary,
  },
  components: {
    Fieldset: Fieldset.extend({
      classNames: {
        root: classes.fieldsetRoot,
      },
    }),
    TextInput: TextInput.extend({
      classNames: {
        root: classes.textInputRoot,
        input: classes.textInputInput,
        label: classes.textInputLabel,
        wrapper: classes.textInputWrapper,
        error: classes.textInputError,
      },
    }),
    Input: Input.extend({
      classNames: {
        input: classes.inputInput,
        wrapper: classes.inputWrapper,
      },
    }),
    Textarea: Textarea.extend({
      classNames: {
        root: classes.textAreaRoot,
        input: classes.textAreaInput,
        label: classes.textAreaLabel,
        wrapper: classes.textAreaWrapper,
      },
      defaultProps: {
        maxRows: 6,
      },
    }),
    Select: Select.extend({
      classNames: {
        root: classes.textInputRoot,
        input: classes.textInputInput,
        wrapper: classes.textInputWrapper,
        label: classes.textInputLabel,
      },
    }),
    NumberInput: NumberInput.extend({
      classNames: {
        root: classes.textInputRoot,
        input: classes.textInputInput,
        wrapper: classes.textInputWrapper,
        label: classes.textInputLabel,
        controls: classes.textInputControls,
        control: classes.textInputControl,
        error: classes.textInputError,
      },
    }),
    TimeInput: TimeInput.extend({
      classNames: {
        root: classes.textInputRoot,
        input: classes.textInputInput,
        wrapper: classes.textInputWrapper,
        label: classes.textInputLabel,
      },
    }),
    DateInput: DateInput.extend({
      classNames: {
        root: classes.textInputRoot,
        input: classes.textInputInput,
        wrapper: classes.textInputWrapper,
        label: classes.textInputLabel,
      },
    }),
    DatePickerInput: DatePickerInput.extend({
      classNames: {
        root: classes.textInputRoot,
        input: classes.textInputInput,
        wrapper: classes.textInputWrapper,
        label: classes.textInputLabel,
      },
    }),
    ColorInput: ColorInput.extend({
      classNames: {
        root: classes.colorInputRoot,
        input: classes.colorInputInput,
        wrapper: classes.colorInputWrapper,
        label: classes.colorInputLabel,
      },
    }),
  },
});
