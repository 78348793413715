import { type ReactElement } from "react";
import { type CardProps } from "../cards/HomeCards";
import HomeDynamicCard from "./HomeCard";
import { type Schemas } from "@/types";
import classes from "../../Home.module.css";
import {
  type Graph,
  type GraphReturn,
  type ResourcePath,
} from "../../HomeDashboard/HomeDashboard";

interface GetCardsParams {
  firstDay: Date;
  lastDay: Date;
  setActiveGraphReturn: (value: GraphReturn) => void;
  setActiveGraph: (value: Graph) => void;
  setResourcePath: (value: ResourcePath) => void;
  setFilter: (value: string) => void;
  setTitle: (value: string) => void;
}

export function getCards({
  firstDay,
  lastDay,
  setActiveGraphReturn,
  setActiveGraph,
  setResourcePath,
  setTitle,
  setFilter,
}: GetCardsParams): ReactElement<CardProps>[] {
  return [
    <HomeDynamicCard<
      Schemas["AppointmentRetrieveDto"],
      Schemas["AppointmentRetrieveDtoPagedList"]
    >
      key="appointments"
      title="APPOINTMENTS CREATED"
      resourcePath="/api/Appointments"
      queryKey="appointmentList"
      goal={1000}
      filterDates={{ start: firstDay, end: lastDay }}
      onClick={() => {
        setActiveGraphReturn("AppointmentRetrieveDtoPagedList");
        setActiveGraph("AppointmentRetrieveDto");
        setResourcePath("/api/Appointments");
        setFilter("");
        setTitle("Appointments Created");
      }}
      className={classes.homeCard}
    />,
    <HomeDynamicCard<
      Schemas["AppointmentRetrieveDto"],
      Schemas["AppointmentRetrieveDtoPagedList"]
    >
      key="appointmentsNoShow"
      title="APPOINTMENTS NO SHOW"
      resourcePath="/api/Appointments"
      queryKey="appointmentList"
      filter={`appointmentStatus == "NoShow"`}
      inverted
      goal={1000}
      filterDates={{ start: firstDay, end: lastDay }}
      onClick={() => {
        setActiveGraphReturn("AppointmentRetrieveDtoPagedList");
        setActiveGraph("AppointmentRetrieveDto");
        setResourcePath("/api/Appointments");
        setFilter(`appointmentStatus == "NoShow"`);
        setTitle("Appointments No Show");
      }}
      className={classes.homeCard}
    />,
    <HomeDynamicCard<
      Schemas["AppointmentRetrieveDto"],
      Schemas["AppointmentRetrieveDtoPagedList"]
    >
      key="appointments"
      title="APPOINTMENTS SPACE TOUR"
      resourcePath="/api/Appointments"
      queryKey="appointmentList"
      filter={`appointmentType == "SpaceTour"`}
      goal={1000}
      filterDates={{ start: firstDay, end: lastDay }}
      onClick={() => {
        setActiveGraphReturn("AppointmentRetrieveDtoPagedList");
        setActiveGraph("AppointmentRetrieveDto");
        setResourcePath("/api/Appointments");
        setFilter(`appointmentType == "SpaceTour"`);
        setTitle("Appointments Space Tour");
      }}
      className={classes.homeCard}
    />,
    <HomeDynamicCard<
      Schemas["AppointmentRetrieveDto"],
      Schemas["AppointmentRetrieveDtoPagedList"]
    >
      key="appointmentsNoShow"
      title="APPOINTMENTS Converted"
      resourcePath="/api/Appointments"
      queryKey="appointmentList"
      filter={`appointmentStatus == "Completed" && appointmentType == "Visit"`}
      goal={1000}
      filterDates={{ start: firstDay, end: lastDay }}
      onClick={() => {
        setActiveGraphReturn("AppointmentRetrieveDtoPagedList");
        setActiveGraph("AppointmentRetrieveDto");
        setResourcePath("/api/Appointments");
        setFilter(
          `appointmentStatus == "Completed" && appointmentType == "Visit"`,
        );
        setTitle("Appointments Converted");
      }}
      className={classes.homeCard}
    />,
    <HomeDynamicCard<
      Schemas["AppointmentRetrieveDto"],
      Schemas["AppointmentRetrieveDtoPagedList"]
    >
      key="appointmentsNoShow"
      title="APPOINTMENTS Completed"
      resourcePath="/api/Appointments"
      queryKey="appointmentList"
      filter={`appointmentStatus == "Completed"`}
      goal={1000}
      filterDates={{ start: firstDay, end: lastDay }}
      onClick={() => {
        setActiveGraphReturn("AppointmentRetrieveDtoPagedList");
        setActiveGraph("AppointmentRetrieveDto");
        setResourcePath("/api/Appointments");
        setFilter(`appointmentStatus == "Completed"`);
        setTitle("Appointments Completed");
      }}
      className={classes.homeCard}
    />,
    <HomeDynamicCard<
      Schemas["LeadRetrieveDto"],
      Schemas["LeadRetrieveDtoPagedList"]
    >
      key="leadsCreated"
      title="LEADS CREATED"
      resourcePath="/api/Leads"
      queryKey="leadList"
      goal={1000}
      filterDates={{ start: firstDay, end: lastDay }}
      onClick={() => {
        setActiveGraphReturn("LeadRetrieveDtoPagedList");
        setActiveGraph("LeadRetrieveDto");
        setResourcePath("/api/Leads");
        setFilter("");
        setTitle("Leads Created");
      }}
      className={classes.homeCard}
    />,
    <HomeDynamicCard<
      Schemas["LeadRetrieveDto"],
      Schemas["LeadRetrieveDtoPagedList"]
    >
      key="leadsWon"
      title="LEADS WON"
      resourcePath="/api/Leads"
      filter={`processStage == "Won"`}
      queryKey="leadList"
      goal={1000}
      filterDates={{ start: firstDay, end: lastDay }}
      onClick={() => {
        setActiveGraphReturn("LeadRetrieveDtoPagedList");
        setActiveGraph("LeadRetrieveDto");
        setResourcePath("/api/Leads");
        setFilter(`processStage == "Won"`);
        setTitle("Leads Won");
      }}
      className={classes.homeCard}
    />,
    <HomeDynamicCard<
      Schemas["LeadRetrieveDto"],
      Schemas["LeadRetrieveDtoPagedList"]
    >
      key="leadsLost"
      title="LEADS Lost"
      resourcePath="/api/Leads"
      filter={`processStage == "Lost"`}
      queryKey="leadList"
      goal={1000}
      inverted
      filterDates={{ start: firstDay, end: lastDay }}
      onClick={() => {
        setActiveGraphReturn("LeadRetrieveDtoPagedList");
        setActiveGraph("LeadRetrieveDto");
        setResourcePath("/api/Leads");
        setFilter(`processStage == "Lost"`);
        setTitle("Leads Lost");
      }}
      className={classes.homeCard}
    />,
    <HomeDynamicCard<
      Schemas["CaseRetrieveDto"],
      Schemas["CaseRetrieveDtoPagedList"]
    >
      key="casesCreated"
      title="CASES CREATED"
      resourcePath="/api/Cases"
      queryKey="caseList"
      inverted
      goal={1000}
      filterDates={{ start: firstDay, end: lastDay }}
      onClick={() => {
        setActiveGraphReturn("CaseRetrieveDtoPagedList");
        setActiveGraph("CaseRetrieveDto");
        setResourcePath("/api/Cases");
        setFilter("");
        setTitle("Cases Created");
      }}
      className={classes.homeCard}
    />,
  ];
}
