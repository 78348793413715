import { config } from "@/config";
import { getTimeZoneLabel } from "@/features/appUsers/utils";
import { DatesProvider } from "@mantine/dates";
import { type PropsWithChildren } from "react";
import UseUser from "@/hooks/useUser";

export function CustomDatesProvider({ children }: PropsWithChildren) {
  const { finalUser } = UseUser();
  const userTimeZone = finalUser?.timeZone ?? config.DATES.timezoneValue;
  const timeZoneLabel = getTimeZoneLabel(userTimeZone);

  return (
    <DatesProvider
      settings={{
        timezone: timeZoneLabel,
      }}
    >
      {children}
    </DatesProvider>
  );
}
