import {
  createContext,
  useContext,
  useState,
  type ReactNode,
  type SetStateAction,
  type Dispatch,
} from "react";
import { useUserContext } from "../User/useUserContext";
import UseNavBar from "@/hooks/useNavBar";

interface LayoutVisibilityContextType {
  isHeaderVisible: boolean;
  isNavbarVisible: boolean;
  isOmnichannelVisible: boolean;
  activeNavbar: string;
  setActiveNavbar: Dispatch<SetStateAction<string>>;
  setVisibility: Dispatch<
    SetStateAction<{
      isHeaderVisible: boolean;
      isNavbarVisible: boolean;
      isOmnichannelVisible: boolean;
    }>
  >;
}

const defaultVisibility = {
  isHeaderVisible: true,
  isNavbarVisible: true,
  isOmnichannelVisible: false,
  activeNavbar: "",
  setActiveNavbar: () => {
    console.warn("Attempted to set navbar without a LayoutVisibilityProvider");
  },
  setVisibility: () => {
    console.warn(
      "Attempted to set visibility without a LayoutVisibilityProvider",
    );
  },
};

const LayoutVisibilityContext =
  createContext<LayoutVisibilityContextType>(defaultVisibility);

// eslint-disable-next-line react-refresh/only-export-components
export function useLayoutVisibility() {
  return useContext(LayoutVisibilityContext);
}

interface LayoutVisibilityProviderProps {
  children: ReactNode;
}

export const LayoutVisibilityProvider = ({
  children,
}: LayoutVisibilityProviderProps) => {
  const { isOmnichannelActive } = useUserContext();
  const { options, currentAppId } = UseNavBar();
  const [visibility, setVisibility] = useState(() => ({
    isHeaderVisible: true,
    isNavbarVisible: isOmnichannelActive ? false : true,
    isOmnichannelVisible: isOmnichannelActive,
  }));

  const [activeNavbar, setActiveNavbar] = useState(
    options.find((o) => o.value === currentAppId)?.label ?? "",
  );

  return (
    <LayoutVisibilityContext.Provider
      value={{ ...visibility, activeNavbar, setActiveNavbar, setVisibility }}
    >
      {children}
    </LayoutVisibilityContext.Provider>
  );
};
